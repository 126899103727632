<template>
	<div class="gg-container" style="padding: 0;max-height: 600px; overflow: auto;" v-loading.lock="fullscreenLoading">
		<el-form :model="info" :rules="rules" ref="ruleForm" class="demo-ruleForm">
			<div class="gg-grid">
				<el-row :gutter="20">
					<el-col class="dashboard-container-col" :lg="12">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="药品名称：" prop="drug_name">
									<el-input placeholder="请输入药品名称" v-if="type!='view'" v-model="info.drug_name"
										size="small"></el-input>
									<span v-else>{{ info.drug_name }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :lg="12">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="药品首字母：" prop="first_char">
									<el-input placeholder="请输入药品首字母" v-if="type!='view'" v-model="info.first_char"
										size="small"></el-input>
									<span v-else>{{ info.first_char }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					
				</el-row>
				
					<el-row>
						<el-col class="dashboard-container-col" :lg="7">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="代煎状态：" prop="take_decoct_status">
						
											<el-radio-group v-model="info.take_decoct_status" size="mini">
												<el-radio :label="1" border size="mini">开启</el-radio>
												<el-radio :label="0" border size="mini">关闭</el-radio>
											</el-radio-group>
									</el-form-item>
								</div>
							</div>
						</el-col>
						
						<el-col class="dashboard-container-col" :lg="5" v-if="info.take_decoct_status==1">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="代煎零售价：" prop="take_price">
										<el-input placeholder="代煎零售价：" v-if="type!='view'" v-model="info.take_price"
											size="small"></el-input>
										<span v-else>{{ info.take_price }}</span>
									</el-form-item>
					
							
								</div>
							</div>
						</el-col>
					
						<el-col class="dashboard-container-col" :lg="5" v-if="info.take_decoct_status==1">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="代煎基本单位：" prop="take_base_unit">
										<el-select v-model="info.take_base_unit" placeholder="请选择">
											<el-option
											v-for="item in beseUnitOptions"
											:key="item.value"
											:label="item.label"
											:value="item.value">
											</el-option>
										</el-select>
									</el-form-item>
								</div>
							</div>
						</el-col>

						<el-col :span="7" v-if="info.take_decoct_status==1">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="代煎药品规格：">
									<br>
										<div v-for="item,index in info.drug_specs" :key="index" style="display: flex;align-items: center;">
											<el-input placeholder="药品规格（如:15g/袋）"  v-model="item.value"
											size="small"></el-input>
									
											<i class="el-icon-delete" style="margin-left:10px;cursor: pointer;float: right;" v-if="index>0" @click="handDelSpecs(index)"></i>
										</div>
										<el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handAddSpecs()">新增规格</el-button>			
									</el-form-item>
								</div>
							</div>
							
							
						</el-col>
						
					</el-row>
					<el-row :gutter="2" v-if="info.take_decoct_status==1">
						
					</el-row>
					<el-row>
						<el-col class="dashboard-container-col" :lg="7">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="自煎状态：" prop="self_decoct_status">
											<el-radio-group v-model="info.self_decoct_status" size="mini">
												<el-radio :label="1" border>开启</el-radio>
												<el-radio :label="0" border>关闭</el-radio>
											</el-radio-group>
									</el-form-item>
								</div>
							</div>
						</el-col>
						<el-col class="dashboard-container-col" :lg="5" v-if="info.self_decoct_status==1">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="自煎零售价：" prop="self_price">
										<el-input placeholder="自煎零售价：" v-if="type!='view'" v-model="info.self_price"
											size="small"></el-input>
										<span v-else>{{ info.self_price }}</span>
									</el-form-item>
					
							
								</div>
							</div>
						</el-col>
					
						<el-col class="dashboard-container-col" :lg="5" v-if="info.self_decoct_status==1">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="自煎基本单位：" prop="base_unit">
										<el-select v-model="info.base_unit" placeholder="请选择">
											<el-option
											v-for="item in beseUnitOptions"
											:key="item.value"
											:label="item.label"
											:value="item.value">
											</el-option>
										</el-select>
									</el-form-item>
								</div>
							</div>
						</el-col>
						<el-col :lg="7" v-if="info.self_decoct_status==1">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="自煎药品规格：">
									<br>
										<div v-for="item,index in info.self_drug_specs" :key="index" style="display: flex;align-items: center;">
											<el-input placeholder="药品规格（如:15g/袋）"  v-model="item.value"
											size="small"></el-input>
									
											<i class="el-icon-delete" style="margin-left:10px;cursor: pointer;float: right;" v-if="index>0" @click="handDelSpecs(index,'self')"></i>
										</div>
										<el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handAddSpecs('self')">新增规格</el-button>			
									</el-form-item>
								</div>
							</div>
							
							
						</el-col>
						
					</el-row>
					<el-row>
						<el-col :span="24">
							<div class="search-container">
								<div class="search-container-fn-input" style="display: flex; justify-content: space-between; align-items: center">
									<el-form-item label="药品别名：">
									<br>
										<div v-for="item,index in info.drug_alias" :key="index" style="display: flex;align-items: center;">
											<el-input placeholder="药品别名"  v-model="item.alias_name"
											size="small" style="margin-right: 10px;"></el-input>
									
											<el-input placeholder="别名代码"  v-model="item.first_chat"
											size="small"></el-input>
									
											<i class="el-icon-delete" style="margin-left:10px;cursor: pointer;float: right;" v-if="index>0" @click="handDelAlias(index)"></i>
										</div>
										<el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="handAddAlias()">新增别名</el-button>			
									</el-form-item>
								</div>
							</div>
							
							
						</el-col>
					</el-row>
			</div>
			<!-- <div style="padding-left: 7px;color: #606266;font-weight: bold;margin-bottom: 15px;">
				<span>药品备注</span>
			</div>
			<div class="">
				<el-row :gutter="20">
					<el-col class="dashboard-container-col" :span="24">
						<div class="search-container">
							<div class="search-container-fn-input flex-1">
								<el-input type="textarea" placeholder="请输入药品备注" v-model="info.remark"
									v-if="type!=='view'" show-word-limit maxlength="20" style="width: 100%"></el-input>
								<span v-else>{{info.remark}}</span>
							</div>
						</div>
					</el-col>
				</el-row>
			</div> -->
			<div v-if="type=='view' && info.pharmacy_drugs.length > 0">
				<div class="title">
					<h3>药房药品价格</h3>
				</div>
				<el-table v-loading="listLoading" :data="info.pharmacy_drugs" border style="width: 100%">
					<el-table-column prop="pharmacy_no" align="center" width="120px" label="药房编号">
					</el-table-column>

					<el-table-column prop="pharmacy_name" label="药房名称">
					</el-table-column>

					<el-table-column prop="pharmacy_address" label="药房地址">
					</el-table-column>
					<el-table-column prop="sell_price" label="零售价">
						<template slot-scope="{row}">
							<span>
								￥{{row.sell_price}}
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="cost_price" label="成本价">
						<template slot-scope="{row}">
							<span>
								￥{{row.cost_price}}
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="stock_quantity" label="库存">
						<template slot-scope="{row}">
							<span>
								{{row.stock_quantity}}件
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="status_name" label="状态">
					</el-table-column>
				</el-table>
			</div>
			<div class=" bbox xright">
				<el-button size="small" @click="cancel">取消</el-button>
				<el-button type="primary" size="small" @click="saveForm('ruleForm')">保存</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import {
		tcDrugDetail,
		
	} from '@/api/drugs'
	import {
		editTCWDrugNew,
		addTcDrug
	} from '@/api/drugs2.0'
	import {
		Message
	} from "element-ui";
	import {
		mapState
	} from "vuex";
	import {
		callMethod,
		getQNToken
	} from "@/api/basic";
	import config from "@/utils/config";

	export default {
		name: "tcDetails",
		props: {
			id: {
				type: [Number, String]
			},
			type: {
				type: String,
				default: 'add' // add  edit  view
			},
			drugDetails:{
				type:Object,
				default:null
			}
		},
		data() {
			return {
				info: {
					pharmacy_drugs: [],
					drug_specs:[
						{value:""}
					],
					drug_alias:[
						{first_chat:'',alias_name:''}
					],
					self_drug_specs:[
						{value:""}
					]
					
				},
				drugInfo: {},
				activeTab: 'first',
				actionPath: 'http://upload.qiniup.com',
				qiniuUrl: config.qiniuUrl,
				qiniuData: {
					token: "",
					key: ""
				},
				beseUnitOptions:[
					{label:'克',value:1},
					{label:'袋',value:2},
					{label:'瓶',value:3},
					{label:'个',value:4},
					{label:'盒',value:5},
					{label:'支',value:6},
				],
				fullscreenLoading: false,
				isOrNotOptions: [{
					label: '是',
					value: true
				}, {
					label: '否',
					value: false
				}, ],
				drugCategoryOptions: [{
						label: '中药颗粒',
						value: 1
					},
					// {label: '西药', value: 2},
					// {label: '中成药', value: 3},
					{
						label: '中药饮片',
						value: 4
					},
				],
				rules: {
					drug_name: [{
						required: true,
						message: '请输入药品名称',
						trigger: 'blur'
					}],
					
					first_char: [{
						required: true,
						message: '请输入药品首字母',
						trigger: 'blur'
					}],
					base_unit: [{
						required: true,
						message: '请选择基本单位',
						trigger: 'change'
					}],
					single_price: [{
						required: true,
						message: '请输入建议零售价',
						trigger: 'blur'
					}],
					take_decoct_status: [{
						required: true,
						message: '请选择代煎状态',
						trigger: 'change'
					}],
					self_decoct_status: [{
						required: true,
						message: '请选择自煎状态',
						trigger: 'change'
					}],
					
			
				}
			}
		},
		created() {
			this.info = this.drugDetails
			// if (this.type == 'edit' && this.id) {
			// 	this.getDetail()
			// }
		},
		mounted() {
			this.$store.dispatch('dictionary/getDicData', [{
				stateName: 'drugs',
				api: '/admin/drug/getDrugDicts'
			}]);

		},
		computed: {
			...mapState({
				drugUnits: (state) => state.dictionary.drugs.drug_unit,
				drugForms: (state) => state.dictionary.drugs.drug_dosage,
			}),
		},
		methods: {
			handAddSpecs(type='take'){
				if(type == 'take'){
					this.info.drug_specs.push({value:''})
				}else{
					this.info.self_drug_specs.push({value:''})
				}
				
			},
			handDelSpecs(index,type='take'){
				if(type == 'take'){
					this.info.drug_specs.splice(index,1)
				}else{
					this.info.self_drug_specs.splice(index,1)
				}
				
			},
			handAddAlias(){
				this.info.drug_alias.push({alias_name:'',first_chat:''})
			},
			handDelAlias(index){
				this.info.drug_alias.splice(index,1)
			},
			cancel() {
				this.$emit('cancel')
			},
			getToken() {
				getQNToken().then(response => {
					let data = response
					if (data.code == 200) {
						this.qiniuData.token = data.data.token
					}
				}).catch(error => {
					console.log(error);
				})
			},
			beforeAvatarUpload(file) {
				this.qiniuData.key = file.name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
				// debugger
				const isJPG = file.type === "image/jpeg" || file.type === "image/png";
				const isLt10M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt10M;
			},
			uploadPhotoSuccess(res) {
				this.info.overview_url = res.key;
			},

			uploadDescImgSuccess(res) {
				this.info.desc_pic.push(res.key);
			},

			removeDescImg(index) {
				// eslint-disable-next-line no-debugger
				this.info.desc_pic.splice(index, 1)
			},

			getDetail() {

				let params = {}
				params.id = this.id
				tcDrugDetail(params).then(response => {
					let data = response
					console.log(data);
					if (data.code == 200) {
						this.info = data.data
						console.log(this.info);
					} else {
						Message({
							message: data.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				}).catch(error => {
					console.log(error);
				})
			},

			saveForm(form) {
				this.$refs[form].validate((valid, object) => {
					if (!valid) {
						let item;
						for (let key in object) {
							item = object[key][0]
							break
						}
						this.$message({
							type: 'error',
							message: item.message
						});
						return
					} else {
						let params = {}
						let url = ''
						params = {
							...this.info
						}
						if (this.type == 'add') {
							addTcDrug(params).then(response => {
								let data = response
								if (data.code == 200) {
									this.$message({
										type: 'success',
										message: '药品新增成功'
									});
								} else {
									this.$message({
										type: 'error',
										message: data.message
									});
								}
								this.fullscreenLoading = false
								this.$emit('editSuccess',{code:data.code})
							}).catch(error => {
								this.$message({
									type: 'error',
									message: error.response.data.message
								});
								this.fullscreenLoading = false
							})
						} else {
							//编辑
							params.id = this.id
							editTCWDrugNew(params).then(response => {
								let data = response
								if (data.code == 200) {
									this.$message({
										type: 'success',
										message: '编辑成功！'
									});
								} else {
									this.$message({
										type: 'error',
										message: data.message
									});
								}
								this.fullscreenLoading = false
								this.$emit('editSuccess',{code:data.code})
							}).catch(error => {
								this.$message({
									type: 'error',
									message: error.response.data.message
								});
								this.fullscreenLoading = false
							})
						}

						this.fullscreenLoading = true

					}
				})
			},
			filterCDNUrl(url) {
				if (!url) {
					return ''
				} else {
					if (url.indexOf('http') > -1) {
						return url
					} else {
						return config.qiniuUrl + url
					}
				}
			}
		}

	}
</script>

<style scoped lang="scss">
	.gg-container {
		overflow-x: hidden !important;
	}

	.el-form-item {
		display: flex;
		margin-bottom: 0;
		align-items: center;
	}

	.gg-grid {
		padding: 0 !important;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #D9D9D9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8C939D;
		width: 298px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}


	.elUpload {
		text-align: center;
		width: 298px;
		position: relative;

		.avatar-uploader {
			border: 1px dotted #666;
		}

		.el-upload {
			width: 100%;

			.avatar-uploader-icon {
				width: 100%;
			}
		}

		.avatar {
			max-width: 100%;
		}

		.del {
			position: absolute;
			right: 10px;
			top: 10px;
			color: #fff;
			font-size: 30px;
			color: #ff4949;
			cursor: pointer;
		}
	}


	.avatar {
		width: 298px;
		height: 178px;
		display: block;
	}

	.descimg {
		width: 298px;
		height: 178px;
		display: block;
		border: 1px dotted;
		position: relative;
		overflow: hidden;

		.del {
			position: absolute;
			right: 10px;
			top: 10px;
			color: #ff4949;
			font-size: 30px;
			cursor: pointer;
		}

		.img {
			width: 298px;
			height: 178px;
		}
	}

	.title {
		border-left: 5px solid #1890ff;
		padding: 0 10px;
		border-radius: 2px;
	}

	//::v-deep.el-form-item__label {
	//     display: block;
	//   }
	::v-deep.el-form-item {
		display: block;
	}
</style>
