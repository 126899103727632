<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>药品状态：</label>
        <el-select
          style="width: 200px"
          v-model="searchParams.enable_status"
          clearable
          placeholder="请选择"
          size="small"
          @change="getList('restPage')"
        >
          <el-option
            v-for="item in [
              {
                value: 1,
                label: '启用',
              },
              {
                value: 2,
                label: '禁用',
              },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="delDrug(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="
              dialogDrugVisible = true;
              openType = 'edit';
              currDrugId = multipleSelectionPres[0].id;
              drugInfo = multipleSelectionPres[0];
            "
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="editDrug('add')"
            >新增
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="getDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              <el-switch
                v-model="scope.row[column.column_prop]"
                active-text="启用"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#C8C8C8"
                :active-value="1"
                :inactive-value="2"
                @change="changeStatus($event, scope.row)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'text' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="column.column_label == '药品规格'">
              <a
                v-if="
                  !scope.row.drug_specs ||
                    (scope.row.drug_specs.length == 1 &&
                      !scope.row.drug_specs[0].value)
                "
              >
                g
              </a>
              <div v-else>
                <el-tag
                  type="primary"
                  v-for="item in scope.row.drug_specs"
                  size="mini"
                  :key="item.id"
                  style="margin-right: 5px;"
                  >{{ item.value }}</el-tag
                >
              </div>
            </div>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'flow_status_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(
                    column.column_prop,
                    searchParams.flow_status_name
                  )
                "
                v-model="searchParams.flow_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template
                  v-for="item in [
                    {
                      value: 1,
                      label: '启用',
                    },
                    {
                      value: 2,
                      label: '禁用',
                    },
                  ]"
                >
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="searchParams.created_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.created_at)
                "
              >
              </el-date-picker>
              <el-input
                v-if="'drug_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.keywords"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.drug_name)
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <!--新增药品-->
    <el-dialog
      :visible.sync="dialogDrugVisible"
      v-if="dialogDrugVisible"
      width="800px"
      top="200px"
    >
      <div
        slot="title"
        class="bbox xbetween ycenter "
        style="padding-right: 50px"
      >
        <div style="font-weight: bold;font-size: 16px;">{{ dialogTitle }}</div>
        <!-- <div>
					  <el-button type="primary" size="mini" v-if="openType=='view'"
						  @click="dialogDrugVisible=true;openType='edit';">编辑</el-button>
					  <el-button type="info" size="mini" v-if="openType=='edit'"
						  @click="dialogDrugVisible=true;openType='view';">取消编辑</el-button>
				  </div> -->
      </div>
      <tcDetails
        @cancel="cancel"
        :type="openType"
        :id="currDrugId"
        :drugDetails="drugInfo"
        @editSuccess="editSuccess"
      ></tcDetails>
    </el-dialog>

    <!--    导入-->
    <el-dialog
      title="药品导入"
      :visible.sync="dialogVisible4"
      width="770px"
      v-if="dialogVisible4"
    >
      <div class="bbox uploadExcel xbetween ycenter">
        <a href="https://pic.gegejk.com/drug_import_TC.xlsx" target="_blank"
          >下载Excel模板</a
        >
        <div class="fileName">
          {{ uploadFileName }}
        </div>
        <div>
          <el-upload
            class="avatar-uploader"
            :auto-upload="true"
            accept=".xlsx"
            :show-file-list="false"
            :on-success="uploadExcelSuccess"
            :on-change="beforeAvatarUpload"
            :http-request="exportFiles"
          >
            <el-button size="small" class="uploadBtn">选择文件</el-button>
          </el-upload>
        </div>
      </div>
      <div class="text-gray" style="font-size:14px; margin-top: 10px;">
        提醒：请按照后台提供的格式上传表格进行导入。
      </div>

      <span slot="footer" class="dialog-footer">
        <!--          <el-button size="small" @click="dialogVisible4=false">取消</el-button>-->
        <el-button type="primary" size="small" @click="dialogVisible4 = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import editTableHead from "@/components/editTableHead/editTableHead";
import {
  getTcmDrugs,
  delTcmDrug,
  enableDrug,
  tcDrugsImport,
  statusTcmDrug,
} from "@/api/drugs";
import { getDrug, putStatus, getAdminFieldIndex } from "@/api/drugs2.0";
import tcDetails from "./components/tcDetail2.0";
import { mapState } from "vuex";
import config from "@/utils/config";
import { getQNToken } from "@/api/basic";
export default {
  name: "tcMedicine",
  components: {
    Pagination,
    tcDetails,
    editTableHead,
  },
  data() {
    return {
      table_type: 'TCMList',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "drug_name",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
        {
          name: "status",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        created_at: [],
        keywords: "",
        status: "",
      },
      drugInfo: {},
      dialogDrugVisible: false,
      dialogVisible4: false,
      openType: "",
      currDrugId: null,
      serachParams: {
        orderTime: [],
      },
      options: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      drugCategoryOptions: [
        {
          label: "中药颗粒",
          value: 1,
        },
        // { label: '西药', value: 2 },
        // { label: '中成药', value: 3 },
        {
          label: "中药饮片",
          value: 4,
        },
      ],
      isOrNotOptions: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      tableData: [],

      actionPath: "http://upload.qiniup.com",
      qiniuData: {
        token: "",
        key: "",
      },
      uploadFile: "",
      uploadFileName: "",
    };
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
    this.getToken();
    setTimeout(() => {
      console.log(this.operateList, 23333, "operateList");
    }, 5000);
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    // 药品分类
    this.$store.dispatch("dictionary/getDicData", [
      {
        stateName: "drugSort",
        api: "/admin/drugManage/getDrugCateSelect",
      },
    ]);
  },
  computed: {
    dialogTitle() {
      return (
        {
          add: "添加",
          edit: "编辑",
          view: "查看药品",
        }[this.openType] || "添加"
      );
    },
    ...mapState({
      drugSort: (state) => state.dictionary.drugSort,
      table_options: (state) => state.user.table_options,
    }),
  },
  methods: {
    handleCustomerDetail(row, e) {
      console.log(row, e);
      if (e == "doctor_name") {
        this.getInfo(row.doctors_id);
      } else if (e == "professionalName") {
        this.handleSetInquiryPrice(row);
      } else if (e == "show_sort") {
        this.handleSort(row);
      } else if (e == "is_bind") {
        this.handleAssistant(row);
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "TCMList",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.TCMList;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    cancel() {
      this.dialogDrugVisible = false;
    },
    changeStatus(e, row) {
      statusTcmDrug(row.id, e)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已启用" : "已禁用";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getList(rest) {
      if (rest === "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      params.type = 2;
      getTcmDrugs(params)
        .then((response) => {
          let data = response;
          if (data.code === 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    delDrug(item) {
      //    删除药品
      let params = {};
      params.id = item.drug_id;
      this.$confirm(`是否删除药品'${item.drug_name}'?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          delTcmDrug(item.id)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.message ? data.message : data.msg,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },

    enableStatus(item, status) {
      let params = {};
      params.id = item.id;
      params.enable_status = status;
      let statusName = "";
      if (status == 1) {
        statusName = "启用";
      } else {
        statusName = "禁用";
      }
      this.$confirm(`是否${statusName}药品'${item.drug_name}'?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: status == 1 ? "success" : "error",
      })
        .then(() => {
          enableDrug(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: statusName + "成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg + "," + data.code,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    editDrug(type) {
      this.openType = type;
      if (type == "add") {
        this.drugInfo = {
          drug_specs: [{ value: "" }],
          drug_alias: [{ first_chat: "", alias_name: "" }],
          self_drug_specs: [{ value: "" }],
          take_decoct_status: 1,
          self_decoct_status: 1,
        };
      }
      this.dialogDrugVisible = true;
    },
    editSuccess(e) {
      console.log("新增成功了", e);
      if (e.code == 200) {
        this.dialogDrugVisible = false;
        this.getList("restPage");
      }
    },
    filterCDNUrl(url) {
      if (!url) {
        return "";
      } else {
        if (url.indexOf("http") > -1) {
          return url;
        } else {
          return config.qiniuUrl + url;
        }
      }
    },

    beforeAvatarUpload(file) {
      this.qiniuData.key = file.name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
      if (file.name.indexOf(".") < 0) {
        this.$message.error("上传文件只能为 xls或者xlsx 格式!");
        return;
      }
      let typeArr = file.name.split(".");
      this.uploadFileName = file.name;
      let type = typeArr[typeArr.length - 1];

      const isJPG = type === "xls" || type === "xlsx";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传文件只能为 xls或者xlsx 格式!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }

      this.uploadFile = file;
    },
    uploadExcelSuccess(res) {
      this.uploadFile = res.key;
    },
    getToken() {
      getQNToken()
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.qiniuData.token = data.data.token;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportFiles(file) {
      if (!this.uploadFile) {
        this.$message({
          type: "error",
          message: "请先上传Excel文件",
        });
        return;
      }

      console.log(this.uploadFile);

      const data = new FormData();
      const fileUps = file.file;
      data.append("file", fileUps);

      tcDrugsImport(data)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              type: "info",
              message: `批量导入成功${data.data.success}条，失败${data.data.fail}条`,
            });
            this.dialogVisible2 = false;
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
::v-deep .el-drawer {
  bottom: 0 !important;
}

::v-deep .el-drawer__header {
  padding: 0;
  margin: 0;
}

::v-deep .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
::v-deep .el-table .warning-row {
  color: red;
}
</style>
